import React from 'react';
import Rodal from 'rodal';
import 'rodal/lib/rodal.css';
import HubspotForm from 'react-hubspot-form';
import {
  Box
} from 'theme-ui';
import theme from './theme';
import closeIcon from '../images/icon__close-modal.svg'

function RegistrationModal(props) {
  return (
    <Rodal
      visible={props.modalVisible}
      onClose={props.onClose}
      closeOnEsc={true}
      showCloseButton={false}
      animation='slideUp'
      customStyles={{
        overflow: 'scroll',
        background: theme.colors.background,
        height: '75%',
        maxWidth: '95%'
      }}>
      <Box
        onClick={props.onClose}
        sx={{
          background: `url(${closeIcon})`,
          height: 25,
          width: 25,
          backgroundSize: 'cover',
          position: 'absolute',
          top: '2%',
          left: '90%',
          cursor: 'pointer'
        }}>
      </Box>
      <HubspotForm
        portalId='8555612'
        formId='27dca707-2277-4870-8f0d-9bfe2011bf79'
        submitButtonClass='hubspot-submit'
        inlineMessage="Thank you for registering! We'll see you soon at Beyond the Glass."
        translations={{
          en: {
            submitText: 'Register' }
        }}
        cssClass='hubspot-form'
      />
    </Rodal>
  )
}

export default RegistrationModal;
