import React from 'react';
import {
  ThemeProvider
} from 'theme-ui';
import theme from '../components/theme';
import GradientBar from '../components/GradientBar';
import Header from '../components/Header';
import RegistrationContent from '../components/RegistrationContent';
import SEO from '../components/seo';
import '../components/layout.scss';

function Register() {
  const [modalIsOpen,setIsOpen] = React.useState(false);

  function openModal() {
    setIsOpen(true)
  }

  function closeModal() {
    setIsOpen(false);
  }

  return (
    <ThemeProvider theme={theme}>
      <SEO title='Register' />
      <GradientBar />
      <Header modalVisible={modalIsOpen} openModal={openModal} onClose={closeModal} showCTA={true} />
      <RegistrationContent modalVisible={modalIsOpen} openModal={openModal} onClose={closeModal} />
    </ThemeProvider>
  );
}

export default Register;

