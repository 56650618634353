import React from 'react';
import {
  Grid,
  Box,
  Text,
  Button,
  Heading
} from 'theme-ui';

import Iframe from 'react-iframe';
import frameBorder from '../images/frame.jpg';
import RegistrationModal from './RegistrationModal';

function RegistrationContent(props) {
  return (
    <div>
      <RegistrationModal modalVisible={props.modalVisible} onClose={props.onClose} />
      <Box
        sx={{
          background: `url(${frameBorder})`,
          backgroundSize: 'cover',
          height: 'calc(46.5vw)',
          maxHeight: 517,
          borderRadius: 10,
          width: '80%',
          maxWidth: 900,
          padding: 3,
          margin: 'auto',
          position: 'relative'
        }}>
        <Box
          sx={{
            width: '100%',
            height: '100%',
            position: 'absolute',
            top: '2%',
            left: '2%'
          }}>
          <Iframe
            src="https://player.vimeo.com/video/469053658?controls=0&autoplay=1&loop=1&muted=1"
            width="96%"
            height="96%"
            position="absolute"
            frameBorder="0"
            allow="autoplay; fullscreen"
          />
        </Box>
      </Box>
      <Grid
        sx={{
          justifyItems: 'center'
        }}>
        <Box
          sx={{
            py: 4,
            px: 2,
            textAlign: 'center'
          }}>
          <Heading
            sx={{
              color: 'vizioRed',
              fontSize: [3, null, 5],
              fontWeight: 'normal',
              pb: 3
            }}>
            Join us for an immersive journey<br />
            through the heartbeat of the home
          </Heading>
          <Text
            sx={{
              fontStyle: 'italic',
              color: 'white',
              fontSize: [2, null, 3],
              pb: 4
            }}>
            presented by VIZIO
          </Text>
          <Text
            sx={{
              color: 'white',
              maxWidth: '30em',
              fontSize: [2, null, 3],
              lineHeight: 'body',
              pb: 4
            }}>
            As we go Beyond The Glass, attendees will explore VIZIO from the inside out - a world of endless entertainment and innovation, direct-to-device advertising,
  data-powered insights and the future of addressable TV.
          </Text>
          <Text
            sx={{
              color: 'white',
              maxWidth: '30em',
              fontSize: [2, null, 3],
              lineHeight: 'body',
              pb: 4
            }}>
            Experiences available on November 11th and November 12th
          </Text>
          <Text
            sx={{
              color: 'white',
              maxWidth: '30em',
              fontSize: [2, null, 3],
              lineHeight: 'body',
              pb: 4,
            }}>
            Participants will have an opportunity to win various VIZIO products
  including the latest TVs and Sound Bars
          </Text>
          <Button
            onClick={props.openModal}
            variant='primary'>
            Register
          </Button>
        </Box>
      </Grid>
    </div>
  )
}

export default RegistrationContent;
